import './style.css'
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import Stats from 'three/examples/jsm/libs/stats.module'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { gsap, ScrollTrigger } from "gsap/all";
//import * as dat from 'dat.gui';

//stats
let stats = new Stats();
//document.body.appendChild( stats.dom );
//scene renderer and controls setup
const scene = new THREE.Scene();
scene.background = new THREE.Color(0x000000);
const fog = new THREE.FogExp2( 0x000000, 0.0 );
scene.fog = fog;
const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 0.1, 15);
camera.aspect = window.innerWidth / window.innerHeight;
const renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: window.devicePixelRatio === 1,
    canvas: document.querySelector("canvas.webgl")
});


renderer.setSize(window.innerWidth, window.innerHeight)
renderer.setPixelRatio(Math.min(1.5, window.devicePixelRatio))
renderer.physicallyCorrectLights = true;

const controls = new OrbitControls( camera, renderer.domElement );
controls.enableZoom = false;
controls.enableRotate = false;
controls.enablePan = false;
let mouseMoved = false;
//raycaster
let raycaster = new THREE.Raycaster();
let pointer = {x: 0, y: 0};
document.body.addEventListener("pointermove", (e)=>{
    pointer.x = ( e.clientX / window.innerWidth ) * 2 - 1;
    pointer.y =  - ( e.clientY / window.innerHeight ) * 2 + 1;
    mouseMoved = true;
}, false);
document.body.addEventListener("touchmove", (e)=>{
    pointer.x = ( e.changedTouches[0].clientX / window.innerWidth ) * 2 - 1;
    pointer.y =  - ( e.changedTouches[0].clientY / window.innerHeight ) * 2 + 1;
    mouseMoved = true;
},false);

//gltfLoader
const loader = new GLTFLoader();

//instanca
const [ pyramidGLB ] = await Promise.all( [ loader.loadAsync("./instanca.glb") ] );

const pyramid = pyramidGLB.scene.children[0];
const pyramidGeo = pyramid.geometry;
const material = new THREE.MeshBasicMaterial({color: 0xcccccc, side: THREE.DoubleSide});
const colors = [
    /*new THREE.Color(0x007FFF),
    new THREE.Color(0x004BB5),
    new THREE.Color(0x3D1FAB),
    new THREE.Color(0x663EAF),
    new THREE.Color(0x8331B9),
    new THREE.Color(0x8331B9)
    new THREE.Color(0x007fff),
    new THREE.Color(0xfe0089),
    new THREE.Color(0xb20060),
    new THREE.Color(0xfedc00),
    new THREE.Color(0xb29a00),
    new THREE.Color(0x8331B9)*/
    new THREE.Color(0x4EFFEF),
    new THREE.Color(0x73A6AD),
    new THREE.Color(0x9B97B2),
    new THREE.Color(0xD8A7CA),
    new THREE.Color(0xC7B8EA),
    new THREE.Color(0xC7B8EA)
];

//helper arrays
let originalPositionsDiamond = [];
let originalDirectionsDiamond = [];
let hoveredDiamond = [];

let originalPositionsSijalica = [];
let originalDirectionsSijalica = [];
let hoveredSijalica = [];

let originalPositionsRukovanje = [];
let originalDirectionsRukovanje = [];
let hoveredRukovanje = [];

let originalPositionsLogo = [];
let originalDirectionsLogo = [];
let hoveredLogo = [];

//let verticesToScaleOnHover = [];
let verticesToResetOnHover = [];
//helper objects
const dummy = new THREE.Object3D();
const matrix = new THREE.Matrix4();
let objectToIntersect = new THREE.Object3D();
let controlsTarget = new THREE.Vector3(-3.9373806229944552, 0.5123119232212439, 0.2605020054963943);
//let controlsTarget = new THREE.Vector3(0, 0, 0);
let curentObject = "diamond";
let animateCamera = true;
let diamondUp = true;
let sijalicaUp = true;
let rukovanjeUp = true;
let logoUp = true;
let middleClickDown = false;
const cameraPositions = {
    "PC": {
        "pos1": {
            "position": {x: 0.24011806303192085, y: 2.8608978752761116, z: 8.733152452414258},
            "target": {x: -3.9373806229944552, y: 6.5123119232212439, z: 0.2605020054963943}
        },
        "pos2": {
            "position": {x: 8.762727554107842, y: 1.84910932795587417, z: 8.6512028677891495},
            "target": {x: -1.1815108504847374, y: 0.676011429745928, z: -4.2800006377906525}
        },
        "pos3":{
            "position": {x: -2.7770642403165526, y: 0.01438341960428053, z: 9.296792824506591},
            "target": {x: -3.9373806229944552, y: 0.5123119232212439, z: 0.2605020054963943}
        },
        "pos4":{
            "position": {x: 0.03723967598600186, y: 0.09137990953841646, z: 9.345057223353699},
            "target": {x: 3.2138159169797906, y: -0.5211507350061212, z: -0.23850981242526195}
        },
        "pos5":{
            "position": {x: 0.03723967598600186, y: 0.09137990953841646, z: 9.345057223353699},
            "target": {x: 3.2138159169797906, y: -0.5211507350061212, z: -0.23850981242526195}
        }
    },
    "mobile":{
        "pos1": {
            "position": {x: 0, y: 0, z: 20},
            "target": {x: 0, y: -500, z: 0}
        },
        "pos2": {
            "position": {x: 0, y: 0, z: 20},
            "target": {x: 0, y: -500, z: 0}
        },
        "pos3":{
            "position": {x: 0, y: 0, z: 20},
            "target": {x: 0, y: -500, z: 0}
        },
        "pos4":{
            "position": {x: 0, y: 0, z: 20},
            "target": {x: 0, y: -500, z: 0}
        },
        "pos5":{
            "position": {x: 0, y: 0, z: 20},
            "target": {x: 0, y: -500, z: 0}
        }
    } 
}
if(window.innerWidth > 1000){
    camera.position.set(cameraPositions.PC.pos1.position.x, cameraPositions.PC.pos1.position.y, cameraPositions.PC.pos1.position.z);
}else{    
    camera.far = 50;
    camera.position.set(cameraPositions.mobile.pos1.position.x, cameraPositions.mobile.pos1.position.y, cameraPositions.mobile.pos1.position.z);
    controlsTarget.set(cameraPositions.mobile.pos1.target.x, cameraPositions.mobile.pos1.target.x, cameraPositions.mobile.pos1.target.x);
    camera.updateProjectionMatrix();
    controls.update();
}
//helper sphere
var testSfera = new THREE.Sphere(0, 0.6);
var sferaGeo = new THREE.SphereGeometry(0.6, 32, 32);
var sfera = new THREE.Mesh(sferaGeo, new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0 }));
scene.add(sfera);

let hoverScaleDuration = 0.5

/* DIJAMANT-START */
let [ diamondGLB ] = await Promise.all( [ loader.loadAsync("./DIJAMANTposle7.glb") ] );;
if(window.innerWidth < 1000){
    [ diamondGLB ] = await Promise.all( [ loader.loadAsync("./dijamant-mobile.glb") ] );
}

const [ DijamantZaPresek ] = await Promise.all([loader.loadAsync("./DijamantZaPresek.glb")]);
const diamondForIntersect = DijamantZaPresek.scene;
const diamond = diamondGLB.scene;
diamondForIntersect.position.y += 1;
diamondForIntersect.traverse(child=>{
    if(child.isMesh){
        child.material = new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0});
    }
})
diamondForIntersect.scale.set(0.65,0.65,0.65);
objectToIntersect = diamondForIntersect;
scene.add(diamondForIntersect);
const diamondGeo = diamond.children[0].geometry;
let objCountDijamant = diamondGeo.attributes.position.array.length;
//scene.add(diamond.children[0]);
let colorIndex = 0;
const meshDijamant = new THREE.InstancedMesh(pyramidGeo, material, objCountDijamant);
scene.add(meshDijamant);
let scaleDijamant = 0.35;
for(let i = 0; i < objCountDijamant; i+=3){
    dummy.scale.set(
        scaleDijamant,
        scaleDijamant,
        scaleDijamant
    );
    originalPositionsDiamond.push(new THREE.Vector3(
        diamondGeo.attributes.position.array[i],
        diamondGeo.attributes.position.array[i + 1],
        diamondGeo.attributes.position.array[i + 2]
    ));
    hoveredDiamond.push(false);
    let dir = new THREE.Vector3();
    dummy.getWorldDirection(dir);
    originalDirectionsDiamond.push(dir); 
    dummy.position.set(
        originalPositionsDiamond[originalPositionsDiamond.length-1].x + dir.x*12.5,
        originalPositionsDiamond[originalPositionsDiamond.length-1].y - Math.abs(dir.y*12.5),
        originalPositionsDiamond[originalPositionsDiamond.length-1].z + dir.z*12.5,    
    )

    dummy.rotation.set(
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI
    );

    dummy.updateMatrix();
    meshDijamant.setColorAt(i, new THREE.Color(diamondGeo.attributes.color.array[colorIndex], diamondGeo.attributes.color.array[colorIndex+1], diamondGeo.attributes.color.array[colorIndex+2]));
    colorIndex+=4;
    meshDijamant.setMatrixAt(i, dummy.matrix);
}
meshDijamant.rotation.y = Math.PI;
window.scrollTo({top: 0, left: 0, behavior: "instant"});
setTimeout(()=>{
    document.querySelector("#loader").className += "fade-out";
    setTimeout(()=>{
        document.querySelector("#loader").style.opacity = 0;
        document.querySelector("div.intro-dot").className += " turnOnAnimation";
        setTimeout(()=>{
            let introValues = {value: 12.5, angle: Math.PI};
            gsap.to(introValues, {value: 0, angle: 0,
                duration: 1.5,
                onUpdate: ()=>{
                    window.scrollTo({top: 0, left: 0, behavior: "instant"})
                    meshDijamant.rotation.y = introValues.angle;
                    for(let i = 0; i < objCountDijamant; i+=3){
                        meshDijamant.getMatrixAt(i, matrix);
                        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                        dummy.position.x = originalPositionsDiamond[i/3].x + originalDirectionsDiamond[i/3].x * introValues.value
                        dummy.position.y = originalPositionsDiamond[i/3].y - Math.abs(originalDirectionsDiamond[i/3].y) * introValues.value
                        dummy.position.z = originalPositionsDiamond[i/3].z + originalDirectionsDiamond[i/3].z * introValues.value
                
                        dummy.updateMatrix();
                
                        meshDijamant.setMatrixAt(i, dummy.matrix);
                    }
                    meshDijamant.instanceMatrix.needsUpdate = true;
                },
            });
        }, 1000)
    }, 1900)
},2800)
gsap.registerPlugin(ScrollTrigger);
let tl = gsap.timeline({
    scrollTrigger:{
        trigger: "#body",
        start: "top top",
        end:document.querySelector("#section1").offsetTop+document.querySelector("#section1").offsetHeight,
        scrub: 0.000000001,
        fastScrollEnd: true,
        onUpdate: ()=>{
            meshDijamant.rotation.y = lamdaDijamant.angle;
            for(let i = 0; i < objCountDijamant; i+=3){
                meshDijamant.getMatrixAt(i, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(scaleDijamant, scaleDijamant, scaleDijamant);
                dummy.position.x = originalPositionsDiamond[i/3].x + originalDirectionsDiamond[i/3].x * lamdaDijamant.value
                dummy.position.y = originalPositionsDiamond[i/3].y - Math.abs(originalDirectionsDiamond[i/3].y) * lamdaDijamant.value
                dummy.position.z = originalPositionsDiamond[i/3].z + originalDirectionsDiamond[i/3].z * lamdaDijamant.value
        
                dummy.updateMatrix();
        
                meshDijamant.setMatrixAt(i, dummy.matrix);
            }
            meshDijamant.instanceMatrix.needsUpdate = true;
        },
        onEnterBack: ()=>{
            curentObject = "";
            resetObject(meshDijamant, diamondGeo, scaleDijamant, hoveredDiamond, originalPositionsDiamond, originalDirectionsDiamond);
        }
    },
    onReverseComplete: ()=>{
        curentObject = "diamond";
        testSfera.radius = 0.6;
        objectToIntersect = diamondForIntersect;
    },
    onStart: ()=>{
        curentObject = "";
        resetObject(meshDijamant, diamondGeo, scaleDijamant, hoveredDiamond, originalPositionsDiamond, originalDirectionsDiamond);
    }
})
let lamdaDijamant = {value: 0, angle: 0};
tl.to(lamdaDijamant, {value: 12.5, angle: Math.PI});


/* /DIJAMANT */

/* SIJALICA-START */
const [ sijalicaGLB ] = await Promise.all( [ loader.loadAsync("./ObojenaSijalicaTest.glb") ] );
const sijalica = sijalicaGLB.scene;
sijalica.traverse(child=>{
    if(child.isMesh){
        child.material = new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0});
    }
})
sijalica.rotation.x -= Math.PI/2;
sijalica.position.z += 2;
//sijalica.position.y -=4;
scene.add(sijalica);
const sijalicaGeo = sijalica.children[0].geometry;
let objCountSijalica = sijalicaGeo.attributes.position.array.length;

const meshSijalica = new THREE.InstancedMesh(pyramidGeo, material, objCountSijalica);
meshSijalica.scale.set(1,1,1);
scene.add(meshSijalica);
colorIndex = 0;
let scaleSijalica = 0.5;
meshSijalica.rotation.y = Math.PI;
for(let i = 0; i < objCountSijalica; i+=3){
    let scale = scaleSijalica;
    dummy.scale.set(
        scale,
        scale,
        scale
    );
    originalPositionsSijalica.push(new THREE.Vector3(
        sijalicaGeo.attributes.position.array[i],
        sijalicaGeo.attributes.position.array[i + 1],
        sijalicaGeo.attributes.position.array[i + 2]
    ));
    hoveredSijalica.push(false);
    let dir = new THREE.Vector3();
    dummy.getWorldDirection(dir);
    originalDirectionsSijalica.push(dir); 

    dummy.position.set(
        originalPositionsSijalica[originalPositionsSijalica.length-1].x + dir.x*12.5,
        originalPositionsSijalica[originalPositionsSijalica.length-1].y - Math.abs(dir.y)*12.5,
        originalPositionsSijalica[originalPositionsSijalica.length-1].z + dir.z*12.5,    
    )

    dummy.rotation.set(
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI
    );

    dummy.updateMatrix();
    
    meshSijalica.setColorAt(i, new THREE.Color(sijalicaGeo.attributes.color.array[colorIndex], sijalicaGeo.attributes.color.array[colorIndex+1], sijalicaGeo.attributes.color.array[colorIndex+2]));
    colorIndex+=4;
    meshSijalica.setMatrixAt(i, dummy.matrix);
}


let tl1 = gsap.timeline({
    scrollTrigger:{
        trigger: "#body",
        start:document.querySelector("#section2").offsetTop,
        end:document.querySelector("#section2").offsetTop+ document.querySelector("#section2").offsetHeight,
        scrub: 0.000000001,
        fastScrollEnd: true,
        onUpdate: ()=>{
            camera.position.set(lamdaSijalica.cameraPositionX, lamdaSijalica.cameraPositionY, lamdaSijalica.cameraPositionZ);
            controlsTarget.set(lamdaSijalica.controlsTargetX, lamdaSijalica.controlsTargetY, lamdaSijalica.controlsTargetZ);
            meshSijalica.rotation.y = lamdaSijalica.angle;
            for(let i = 0; i < objCountSijalica; i+=3){
                meshSijalica.getMatrixAt(i, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(scaleSijalica, scaleSijalica,scaleSijalica);
                dummy.position.x = originalPositionsSijalica[i/3].x + originalDirectionsSijalica[i/3].x * lamdaSijalica.value
                dummy.position.y = originalPositionsSijalica[i/3].y - Math.abs(originalDirectionsSijalica[i/3].y) * lamdaSijalica.value
                dummy.position.z = originalPositionsSijalica[i/3].z + originalDirectionsSijalica[i/3].z * lamdaSijalica.value
        
                dummy.updateMatrix();
        
                meshSijalica.setMatrixAt(i, dummy.matrix);
            }
            meshSijalica.instanceMatrix.needsUpdate = true;
        },
        onEnterBack: ()=>{
            curentObject = "";  
            resetObject(meshSijalica, sijalicaGeo, scaleSijalica, hoveredSijalica, originalPositionsSijalica, originalDirectionsSijalica);
        },
        onLeaveBack: ()=>{
            resetObject(meshSijalica, sijalicaGeo, scaleSijalica, hoveredSijalica, originalPositionsSijalica, originalDirectionsSijalica);
        }
    },
    onComplete: ()=>{
        curentObject = "sijalica";
        testSfera.radius = 1;
        objectToIntersect = sijalica;
    },
})
//x: , y: , z: 
//x: , y: , z: 
let lamdaSijalica = {value: 12.5, angle: Math.PI, cameraPositionX: cameraPositions.PC.pos1.position.x, cameraPositionY: cameraPositions.PC.pos1.position.y, cameraPositionZ: cameraPositions.PC.pos1.position.z, controlsTargetX: cameraPositions.PC.pos1.target.x, controlsTargetY: cameraPositions.PC.pos1.target.y, controlsTargetZ: cameraPositions.PC.pos1.target.z}; 
tl1.to(lamdaSijalica, {value: 0, angle: 0, cameraPositionX: cameraPositions.PC.pos2.position.x, cameraPositionY: cameraPositions.PC.pos2.position.y, cameraPositionZ: cameraPositions.PC.pos2.position.z, controlsTargetX: cameraPositions.PC.pos2.target.x, controlsTargetY: cameraPositions.PC.pos2.target.y, controlsTargetZ: cameraPositions.PC.pos2.target.z});
if(window.innerWidth < 1000){
    lamdaSijalica = {value: 15, angle: Math.PI, cameraPositionX: cameraPositions.mobile.pos1.position.x, cameraPositionY: cameraPositions.mobile.pos1.position.y, cameraPositionZ: cameraPositions.mobile.pos1.position.z, controlsTargetX: cameraPositions.mobile.pos1.target.x, controlsTargetY: cameraPositions.mobile.pos1.target.y, controlsTargetZ: cameraPositions.mobile.pos1.target.z}; 
    tl1.to(lamdaSijalica, {value: 0, angle: 0, cameraPositionX: cameraPositions.mobile.pos2.position.x, cameraPositionY: cameraPositions.mobile.pos2.position.y, cameraPositionZ: cameraPositions.mobile.pos2.position.z, controlsTargetX: cameraPositions.mobile.pos2.target.x, controlsTargetY: cameraPositions.mobile.pos2.target.y, controlsTargetZ: cameraPositions.mobile.pos2.target.z});
}

let tl2 = gsap.timeline({
    scrollTrigger:{
        trigger: "#body",
        start: document.querySelector("#section3").offsetTop+document.querySelector("#section3").offsetHeight * 0.2,
        end: document.querySelector("#section3").offsetTop+document.querySelector("#section3").offsetHeight,
        scrub: 0.000000001,
        fastScrollEnd: true,
        onUpdate: ()=>{
            meshSijalica.rotation.y = lamdaSijalica.angle;
            for(let i = 0; i < objCountSijalica; i+=3){
                meshSijalica.getMatrixAt(i, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(scaleSijalica, scaleSijalica, scaleSijalica);
                dummy.position.x = originalPositionsSijalica[i/3].x + originalDirectionsSijalica[i/3].x * lamdaSijalica2.value
                dummy.position.y = originalPositionsSijalica[i/3].y - Math.abs(originalDirectionsSijalica[i/3].y) * lamdaSijalica2.value
                dummy.position.z = originalPositionsSijalica[i/3].z + originalDirectionsSijalica[i/3].z * lamdaSijalica2.value
        
                dummy.updateMatrix();
        
                meshSijalica.setMatrixAt(i, dummy.matrix);
            }
            meshSijalica.instanceMatrix.needsUpdate = true;
        }
    },
    onReverseComplete: ()=>{
        curentObject = "sijalica";
        testSfera.radius = 1;
        objectToIntersect = sijalica;
    },
    onStart: ()=>{
      curentObject = "";  
      resetObject(meshSijalica, sijalicaGeo, scaleSijalica, hoveredSijalica, originalPositionsSijalica, originalDirectionsSijalica);  
    }
})
let lamdaSijalica2 = {value: 0, angle: 0};
tl2.to(lamdaSijalica2, {value: 12.5, angle: Math.PI});
/* /SIJALICA */


/* RUKOVANJE-START */
const [ rukovanjeGLB ] = await Promise.all( [ loader.loadAsync("./prstiFinalglb23.glb") ] );
const rukovanje = rukovanjeGLB.scene;
const rukovanjeIntersectObj = new THREE.Mesh(new THREE.PlaneGeometry(10, 7), new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}));
rukovanjeIntersectObj.position.z -= 2.95;
scene.add(rukovanjeIntersectObj);
const rukovanjeGeo = rukovanje.children[0].geometry;
let objCountRukovanje = rukovanjeGeo.attributes.position.array.length;

const meshRukovanje = new THREE.InstancedMesh(pyramidGeo, material, objCountRukovanje);
scene.add(meshRukovanje);
colorIndex = 0;
let scaleRukovanje = 0.45;
meshRukovanje.rotation.y = Math.PI;
for(let i = 0; i < objCountRukovanje; i+=3){
    let scale = scaleRukovanje;
    dummy.scale.set(
        scale,
        scale,
        scale
    );
    originalPositionsRukovanje.push(new THREE.Vector3(
        rukovanjeGeo.attributes.position.array[i],
        rukovanjeGeo.attributes.position.array[i + 1],
        rukovanjeGeo.attributes.position.array[i + 2]
    ));
    hoveredRukovanje.push(false);
    let dir = new THREE.Vector3();
    dummy.getWorldDirection(dir);
    originalDirectionsRukovanje.push(dir); 

    dummy.position.set(
        originalPositionsRukovanje[originalPositionsRukovanje.length-1].x + dir.x*12.5,
        originalPositionsRukovanje[originalPositionsRukovanje.length-1].y - Math.abs(dir.y)*12.5,
        originalPositionsRukovanje[originalPositionsRukovanje.length-1].z + dir.z*12.5,    
    )

    dummy.rotation.set(
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI
    );

    dummy.updateMatrix();
    
    meshRukovanje.setColorAt(i, new THREE.Color(rukovanjeGeo.attributes.color.array[colorIndex], rukovanjeGeo.attributes.color.array[colorIndex+1], rukovanjeGeo.attributes.color.array[colorIndex+2]));
    colorIndex+=4;
    meshRukovanje.setMatrixAt(i, dummy.matrix);
}


let tl3 = gsap.timeline({
    scrollTrigger:{
        trigger: "#body",
        start:document.querySelector("#section4").offsetTop,
        end:document.querySelector("#section4").offsetTop+document.querySelector("#section4").offsetHeight*0.8,
        scrub: 0.000000001,
        fastScrollEnd: true,
        onUpdate: ()=>{
            camera.position.set(lamdaRukovanje.cameraPositionX, lamdaRukovanje.cameraPositionY, lamdaRukovanje.cameraPositionZ);
            controlsTarget.set(lamdaRukovanje.controlsTargetX, lamdaRukovanje.controlsTargetY, lamdaRukovanje.controlsTargetZ);
            meshRukovanje.rotation.y = lamdaRukovanje.angle;
            for(let i = 0; i < objCountRukovanje; i+=3){
                meshRukovanje.getMatrixAt(i, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(scaleRukovanje, scaleRukovanje, scaleRukovanje);
                dummy.position.x = originalPositionsRukovanje[i/3].x + originalDirectionsRukovanje[i/3].x * lamdaRukovanje.value
                dummy.position.y = originalPositionsRukovanje[i/3].y - Math.abs(originalDirectionsRukovanje[i/3].y) * lamdaRukovanje.value
                dummy.position.z = originalPositionsRukovanje[i/3].z + originalDirectionsRukovanje[i/3].z * lamdaRukovanje.value
        
                dummy.updateMatrix();
        
                meshRukovanje.setMatrixAt(i, dummy.matrix);
            }
            meshRukovanje.instanceMatrix.needsUpdate = true;
        },
        onEnterBack: ()=>{
            curentObject = "";
            resetObject(meshRukovanje, rukovanjeGeo, scaleRukovanje, hoveredRukovanje, originalPositionsRukovanje, originalDirectionsRukovanje);
        },
        onLeaveBack: ()=>{
            resetObject(meshRukovanje, rukovanjeGeo, scaleRukovanje, hoveredRukovanje, originalPositionsRukovanje, originalDirectionsRukovanje);
        }
        
    },
    onComplete: ()=>{
        curentObject = "rukovanje";
        testSfera.radius = 1;
        objectToIntersect = rukovanjeIntersectObj;
    }
})
let lamdaRukovanje = {value: 12.5, angle: Math.PI, cameraPositionX: cameraPositions.PC.pos2.position.x, cameraPositionY: cameraPositions.PC.pos2.position.y, cameraPositionZ: cameraPositions.PC.pos2.position.z, controlsTargetX: cameraPositions.PC.pos2.target.x, controlsTargetY: cameraPositions.PC.pos2.target.y, controlsTargetZ: cameraPositions.PC.pos2.target.z};
tl3.to(lamdaRukovanje, {value: 0, angle: 0, cameraPositionX: cameraPositions.PC.pos3.position.x, cameraPositionY: cameraPositions.PC.pos3.position.y, cameraPositionZ: cameraPositions.PC.pos3.position.z, controlsTargetX: cameraPositions.PC.pos3.target.x, controlsTargetY: cameraPositions.PC.pos3.target.y, controlsTargetZ: cameraPositions.PC.pos3.target.z});
if(window.innerWidth < 1000){
    lamdaRukovanje = {value: 15, angle: Math.PI, cameraPositionX: cameraPositions.mobile.pos2.position.x, cameraPositionY: cameraPositions.mobile.pos2.position.y, cameraPositionZ: cameraPositions.mobile.pos2.position.z, controlsTargetX: cameraPositions.mobile.pos2.target.x, controlsTargetY: cameraPositions.mobile.pos2.target.y, controlsTargetZ: cameraPositions.mobile.pos2.target.z};
    tl3.to(lamdaRukovanje, {value: 0, angle: 0, cameraPositionX: cameraPositions.mobile.pos3.position.x, cameraPositionY: cameraPositions.mobile.pos3.position.y, cameraPositionZ: cameraPositions.mobile.pos3.position.z, controlsTargetX: cameraPositions.mobile.pos3.target.x, controlsTargetY: cameraPositions.mobile.pos3.target.y, controlsTargetZ: cameraPositions.mobile.pos3.target.z});
}

let tl4 = gsap.timeline({
    scrollTrigger:{
        trigger: "#body",
        start: document.querySelector("#section5").offsetTop+document.querySelector("#section5").offsetHeight*0.2,
        end:document.querySelector("#section5").offsetTop+document.querySelector("#section5").offsetHeight,
        scrub: 0.000000001,
        fastScrollEnd: true,
        onUpdate: ()=>{
            meshRukovanje.rotation.y = lamdaRukovanje.angle;
            for(let i = 0; i < objCountRukovanje; i+=3){
                meshRukovanje.getMatrixAt(i, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(scaleRukovanje, scaleRukovanje, scaleRukovanje);
                dummy.position.x = originalPositionsRukovanje[i/3].x + originalDirectionsRukovanje[i/3].x * lamdaRukovanje2.value
                dummy.position.y = originalPositionsRukovanje[i/3].y - Math.abs(originalDirectionsRukovanje[i/3].y) * lamdaRukovanje2.value
                dummy.position.z = originalPositionsRukovanje[i/3].z + originalDirectionsRukovanje[i/3].z * lamdaRukovanje2.value
        
                dummy.updateMatrix();
        
                meshRukovanje.setMatrixAt(i, dummy.matrix);
            }
            meshRukovanje.instanceMatrix.needsUpdate = true;
        }
    },
    onReverseComplete: ()=>{
        curentObject = "rukovanje";
        testSfera.radius = 1;
        objectToIntersect = rukovanjeIntersectObj;
    },
    onStart: ()=>{
        curentObject = ""
        resetObject(meshRukovanje, rukovanjeGeo, scaleRukovanje, hoveredRukovanje, originalPositionsRukovanje, originalDirectionsRukovanje);
    }
})
let lamdaRukovanje2 = {value: 0, angle: 0};
tl4.to(lamdaRukovanje2, {value: 12.5, angle: Math.PI});

/* /RUKOVANJE */
/* LOGO-START */

const [ logoGLB ] = await Promise.all( [ loader.loadAsync("./obojeniRepicTest.glb") ] );
const logo = logoGLB.scene;
const logoIntersectObj = new THREE.Mesh(new THREE.PlaneGeometry(10, 7), new THREE.MeshBasicMaterial({color: 0xffffff, transparent: true, opacity: 0, side: THREE.DoubleSide}));
scene.add(logoIntersectObj);
const logoGeo = logo.children[0].geometry;
let objCountLogo = logoGeo.attributes.position.array.length;

const meshLogo = new THREE.InstancedMesh(pyramidGeo, material, objCountLogo);
meshLogo.scale.set(1,1,1);
//meshLogo.rotation.x += Math.PI/2;
scene.add(meshLogo);
colorIndex = 0;
let scaleLogo = 0.5;
meshLogo.rotation.y = Math.PI;
for(let i = 0; i < objCountLogo; i+=3){
    let scale = scaleLogo;
    dummy.scale.set(
        scale,
        scale,
        scale
    );
    originalPositionsLogo.push(new THREE.Vector3(
        logoGeo.attributes.position.array[i],
        logoGeo.attributes.position.array[i + 1],
        logoGeo.attributes.position.array[i + 2]
    ));
    hoveredLogo.push(false);
    let dir = new THREE.Vector3();
    dummy.getWorldDirection(dir);
    originalDirectionsLogo.push(dir); 

    dummy.position.set(
        originalPositionsLogo[originalPositionsLogo.length-1].x + dir.x*12.5,
        originalPositionsLogo[originalPositionsLogo.length-1].y - Math.abs(dir.y)*12.5,
        originalPositionsLogo[originalPositionsLogo.length-1].z + dir.z*12.5,    
    )

    dummy.rotation.set(
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI,
        Math.random() * 2 * Math.PI
    );

    dummy.updateMatrix();
    
    meshLogo.setColorAt(i, new THREE.Color(logoGeo.attributes.color.array[colorIndex], logoGeo.attributes.color.array[colorIndex+1], logoGeo.attributes.color.array[colorIndex+2]));
    colorIndex+=4;
    meshLogo.setMatrixAt(i, dummy.matrix);
}


let tl5 = gsap.timeline({
    scrollTrigger:{
        trigger: "#body",
        start: document.querySelector("#section6").offsetTop,
        end:document.querySelector("#section6").offsetTop+document.querySelector("#section6").offsetHeight,
        scrub: 0.000000001,
        fastScrollEnd: true,
        onUpdate: ()=>{
            /*if(tl5.scrollTrigger.direction == -1 && tl5.progress()>0.7){
                curentObject = "";
                console.log(scaleLogo);
                resetObject(meshLogo, logoGeo, scaleLogo, hoveredLogo, originalPositionsLogo, originalDirectionsLogo);
            }*/
            camera.position.set(lamdaLogo.cameraPositionX, lamdaLogo.cameraPositionY, lamdaLogo.cameraPositionZ);
            controlsTarget.set(lamdaLogo.controlsTargetX, lamdaLogo.controlsTargetY, lamdaLogo.controlsTargetZ);
            meshLogo.rotation.y = lamdaLogo.angle;
            for(let i = 0; i < objCountLogo; i+=3){
                meshLogo.getMatrixAt(i, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(scaleLogo, scaleLogo, scaleLogo);
                dummy.position.x = originalPositionsLogo[i/3].x + originalDirectionsLogo[i/3].x * lamdaLogo.value
                dummy.position.y = originalPositionsLogo[i/3].y - Math.abs(originalDirectionsLogo[i/3].y) * lamdaLogo.value
                dummy.position.z = originalPositionsLogo[i/3].z + originalDirectionsLogo[i/3].z * lamdaLogo.value
        
                dummy.updateMatrix();
        
                meshLogo.setMatrixAt(i, dummy.matrix);
            }
            meshLogo.instanceMatrix.needsUpdate = true;
        },
        onEnterBack: ()=>{
            curentObject = "";
            resetObject(meshLogo, logoGeo, scaleLogo, hoveredLogo, originalPositionsLogo, originalDirectionsLogo);
        }
    },
    onComplete: ()=>{
        curentObject = "logo";
        testSfera.radius = 0.6;
        objectToIntersect = logoIntersectObj;
    },
})
let lamdaLogo = {value: 12.5, angle: Math.PI, cameraPositionX: cameraPositions.PC.pos3.position.x, cameraPositionY: cameraPositions.PC.pos3.position.y, cameraPositionZ: cameraPositions.PC.pos3.position.z, controlsTargetX: cameraPositions.PC.pos3.target.x, controlsTargetY: cameraPositions.PC.pos3.target.y, controlsTargetZ: cameraPositions.PC.pos3.target.z};
tl5.to(lamdaLogo, {value: 0, angle: 0, cameraPositionX: cameraPositions.PC.pos4.position.x, cameraPositionY: cameraPositions.PC.pos4.position.y, cameraPositionZ: cameraPositions.PC.pos4.position.z, controlsTargetX: cameraPositions.PC.pos4.target.x, controlsTargetY: cameraPositions.PC.pos4.target.y, controlsTargetZ: cameraPositions.PC.pos4.target.z});
if(window.innerWidth < 1000){
    lamdaLogo = {value: 15, angle: Math.PI, cameraPositionX: cameraPositions.mobile.pos3.position.x, cameraPositionY: cameraPositions.mobile.pos3.position.y, cameraPositionZ: cameraPositions.mobile.pos3.position.z, controlsTargetX: cameraPositions.mobile.pos3.target.x, controlsTargetY: cameraPositions.mobile.pos3.target.y, controlsTargetZ: cameraPositions.mobile.pos3.target.z};
    tl5.to(lamdaLogo, {value: 0, angle: 0, cameraPositionX: cameraPositions.mobile.pos4.position.x, cameraPositionY: cameraPositions.mobile.pos4.position.y, cameraPositionZ: cameraPositions.mobile.pos4.position.z, controlsTargetX: cameraPositions.mobile.pos4.target.x, controlsTargetY: cameraPositions.mobile.pos4.target.y, controlsTargetZ: cameraPositions.mobile.pos4.target.z});
}
let tl6 = gsap.timeline({
    scrollTrigger:{
        trigger: "#body",
        start: document.querySelector("#section7").offsetTop+document.querySelector("#section7").offsetHeight*0.2,
        end:document.querySelector("#section7").offsetTop+document.querySelector("#section7").offsetHeight,
        scrub: 0.000000001,
        fastScrollEnd: true,
        onUpdate: ()=>{
            camera.position.set(lamdaLogo2.cameraPositionX, lamdaLogo2.cameraPositionY, lamdaLogo2.cameraPositionZ);
            controlsTarget.set(lamdaLogo2.controlsTargetX, lamdaLogo2.controlsTargetY, lamdaLogo2.controlsTargetZ);
            meshLogo.rotation.y = lamdaLogo2.angle;
            for(let i = 0; i < objCountLogo; i+=3){
                meshLogo.getMatrixAt(i, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(scaleLogo, scaleLogo, scaleLogo);
                dummy.position.x = originalPositionsLogo[i/3].x + originalDirectionsLogo[i/3].x * lamdaLogo2.value
                dummy.position.y = originalPositionsLogo[i/3].y - Math.abs(originalDirectionsLogo[i/3].y) * lamdaLogo2.value
                dummy.position.z = originalPositionsLogo[i/3].z + originalDirectionsLogo[i/3].z * lamdaLogo2.value
        
                dummy.updateMatrix();
        
                meshLogo.setMatrixAt(i, dummy.matrix);
            }
            meshLogo.instanceMatrix.needsUpdate = true;
        }
    },
    onStart: ()=>{
        curentObject = "";
        resetObject(meshLogo, logoGeo, scaleLogo, hoveredLogo, originalPositionsLogo, originalDirectionsLogo);
        objectToIntersect = logoIntersectObj;
    },
    onReverseComplete: ()=>{
        curentObject = "logo";
        testSfera.radius = 0.6;
        objectToIntersect = logoIntersectObj;
    }
})
let lamdaLogo2 = {value: 0, angle: 0, cameraPositionX: cameraPositions.PC.pos4.position.x, cameraPositionY: cameraPositions.PC.pos4.position.y, cameraPositionZ: cameraPositions.PC.pos4.position.z, controlsTargetX: cameraPositions.PC.pos4.target.x, controlsTargetY: cameraPositions.PC.pos4.target.y, controlsTargetZ: cameraPositions.PC.pos4.target.z};
tl6.to(lamdaLogo2, {value: 12.5, angle: Math.PI, cameraPositionX: cameraPositions.PC.pos5.position.x, cameraPositionY: cameraPositions.PC.pos5.position.y, cameraPositionZ: cameraPositions.PC.pos5.position.z, controlsTargetX: cameraPositions.PC.pos5.target.x, controlsTargetY: cameraPositions.PC.pos5.target.y, controlsTargetZ: cameraPositions.PC.pos5.target.z});
if(window.innerWidth < 1000){
    lamdaLogo2 = {value: 0, angle: 0, cameraPositionX: cameraPositions.mobile.pos4.position.x, cameraPositionY: cameraPositions.mobile.pos4.position.y, cameraPositionZ: cameraPositions.mobile.pos4.position.z, controlsTargetX: cameraPositions.mobile.pos4.target.x, controlsTargetY: cameraPositions.mobile.pos4.target.y, controlsTargetZ: cameraPositions.mobile.pos4.target.z};
    tl6.to(lamdaLogo2, {value: 15, angle: Math.PI, cameraPositionX: cameraPositions.mobile.pos5.position.x, cameraPositionY: cameraPositions.mobile.pos5.position.y, cameraPositionZ: cameraPositions.mobile.pos5.position.z, controlsTargetX: cameraPositions.mobile.pos5.target.x, controlsTargetY: cameraPositions.mobile.pos5.target.y, controlsTargetZ: cameraPositions.mobile.pos5.target.z});
}
/* /LOGO */
function getVerticesNearPoint(geometry, hovered, mesh){
    
    const vertices = [];
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
        mesh.getMatrixAt(i, matrix);
        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
        if(testSfera.containsPoint(dummy.position)){
            vertices.push(i);
        }else if(hovered[i/3]){
            verticesToResetOnHover.push(i);
        }
    }  
 
    return vertices;
}

function getVerticesToReset(geometry, hovered, mesh){
    const vertices = [];
    const positions = geometry.attributes.position.array;
    for (let i = 0; i < positions.length; i += 3) {
        mesh.getMatrixAt(i, matrix);
        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
        if(hovered[i/3]){
            vertices.push(i);
        }
    }
    return vertices;
}

function increaseVerticesNearPoint(mesh, geometry, scale, hovered, originalPositions, originalDirections){
    let vertices = getVerticesNearPoint(geometry, hovered, mesh);
    resetIncresedVerices(mesh, verticesToResetOnHover, scale, hovered, originalPositions, originalDirections);
    vertices.forEach(index=>{
        
        if(!hovered[index/3]){
            //gsap.from
            hovered[index/3] = true;
            let startValues = {scale: scale, factor: 0};
            
            gsap.to(startValues,{
                scale: scale*1.7,
                factor: 0.2,
                duration: hoverScaleDuration,
                id: "test",
                onUpdate: ()=>{
                    mesh.getMatrixAt(index, matrix);
                    matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                    dummy.scale.set(startValues.scale,startValues.scale,startValues.scale);
                    dummy.position.x = originalPositions[index/3].x + originalDirections[index/3].x * startValues.factor;
                    dummy.position.y = originalPositions[index/3].y + originalDirections[index/3].y * startValues.factor;
                    dummy.position.z = originalPositions[index/3].z + originalDirections[index/3].z * startValues.factor;
                    dummy.updateMatrix();
                    mesh.setMatrixAt(index, dummy.matrix);
                },

            });
        }
    });
}

function resetObject(mesh, geometry, scale, hovered, originalPositions, originalDirections){
    let vertices = getVerticesToReset(geometry, hovered, mesh);
    instantResetIncresedVerices(mesh, vertices, scale, hovered, originalPositions, originalDirections);
}

function resetIncresedVerices(mesh,vertices,scale, hovered, originalPositions, originalDirections){
    vertices.forEach(index=>{
        let startValues = {scale: scale*1.7, factor: 0.2};
        gsap.to(startValues,{
            scale: scale,
            factor: 0,
            duration: hoverScaleDuration,
            onUpdate: ()=>{
                mesh.getMatrixAt(index, matrix);
                matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
                dummy.scale.set(startValues.scale,startValues.scale,startValues.scale);
                dummy.position.x = originalPositions[index/3].x + originalDirections[index/3].x * startValues.factor;
                dummy.position.y = originalPositions[index/3].y + originalDirections[index/3].y * startValues.factor;
                dummy.position.z = originalPositions[index/3].z + originalDirections[index/3].z * startValues.factor;
                dummy.updateMatrix();
                mesh.setMatrixAt(index, dummy.matrix);
            }
        })
        
        hovered[index/3] = false;
    })
    verticesToResetOnHover = [];
}

function instantResetIncresedVerices(mesh,vertices,scale, hovered, originalPositions, originalDirections){
    if(gsap.getById("test")){
        gsap.getById("test").pause(0);
    }
    vertices.forEach(index=>{
        mesh.getMatrixAt(index, matrix);
        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);
        dummy.scale.set(scale,scale,scale);
        dummy.position.x = originalPositions[index/3].x + originalDirections[index/3].x * 0;
        dummy.position.y = originalPositions[index/3].y + originalDirections[index/3].y * 0;
        dummy.position.z = originalPositions[index/3].z + originalDirections[index/3].z * 0;
        dummy.updateMatrix();
        mesh.setMatrixAt(index, dummy.matrix);
        hovered[index/3] = false;        
    });
    verticesToResetOnHover = [];
}


function sphereFunction(mesh, geometry, scale, hovered, originalPositions, originalDirections){
    raycaster.setFromCamera(pointer, camera);
    let intersects = raycaster.intersectObject(objectToIntersect);
    if(intersects.length > 0 && !middleClickDown){
        sfera.position.set(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z);
        testSfera.center = new THREE.Vector3(intersects[0].point.x, intersects[0].point.y, intersects[0].point.z);
        increaseVerticesNearPoint(mesh, geometry, scale, hovered, originalPositions, originalDirections);
    }else{
        sfera.position.set(500,500,500);
        testSfera.center = new THREE.Vector3(500,500,500);
        increaseVerticesNearPoint(mesh, geometry, scale, hovered, originalPositions, originalDirections);
    }    
}
window.addEventListener('resize', ()=>{
    camera.aspect = window.innerWidth / window.innerHeight;
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
})
document.body.addEventListener("mousedown", (e)=>{
    if(e.button == 1){
        middleClickDown = true;
        gsap.getById("test").pause(0);
    }
}, false);
document.body.addEventListener("mouseup", (e)=>{
    if(e.button == 1){
        middleClickDown = false;
    }
}, false);
/*document.body.addEventListener("click", ()=>{
    console.log(camera.position);
    console.log(controls.target);
})
document.addEventListener("keydown", ()=>{
    animateCamera = !animateCamera;
    controls.enableZoom = !animateCamera;
    console.log("asdasda");
})*/

/* DEBUG-UI */
/*const gui = new dat.GUI();
gui.add(fog, "density", 0, 0.2, 0.001).name("fog");
gui.add(camera, "far", 0.5,50,0.5).name("far-view").onChange(()=>{
    camera.updateProjectionMatrix();
});*/
/* /DEBUG-UI */

//animate and render functions
function render(){
    renderer.render(scene, camera)
    stats.update();
}
function animate() {
    if(mouseMoved && !middleClickDown){
        if(curentObject == "diamond"){
            sphereFunction(meshDijamant, diamondGeo, scaleDijamant, hoveredDiamond, originalPositionsDiamond, originalDirectionsDiamond);
        }
        if(curentObject == "sijalica"){
            sphereFunction(meshSijalica, sijalicaGeo, scaleSijalica, hoveredSijalica, originalPositionsSijalica, originalDirectionsSijalica);
        }
        if(curentObject == "rukovanje"){
            sphereFunction(meshRukovanje, rukovanjeGeo, scaleRukovanje, hoveredRukovanje, originalPositionsRukovanje, originalDirectionsRukovanje);
        }
        if(curentObject == "logo"){
            sphereFunction(meshLogo, logoGeo, scaleLogo, hoveredLogo, originalPositionsLogo, originalDirectionsLogo);
        }
        mouseMoved = false;
    }
    if(animateCamera){
        if(window.innerWidth > 1000){
            gsap.to(controls.target,{
                x: controlsTarget.x + pointer.x,
                z: controlsTarget.z + pointer.y,
                duration: 1,
                
            })
        }/*else{
            gsap.to(controls.target,{
                x: controlsTarget.x + pointer.x,
                z: controlsTarget.z,
                duration: 1,
                
            })
        }*/
        //controls.target.set( controlsTarget.x + pointer.x, controlsTarget.y + pointer.y, controlsTarget.z);
        //controls.target.lerpVectors(controls.target, new THREE.Vector3(controlsTarget.x + pointer.x, controlsTarget.y + pointer.y, controlsTarget.z), 0.3);
    }
    for(let i = 0; i < objCountDijamant; i+=3){
        meshDijamant.getMatrixAt(i, matrix);
        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);

        dummy.rotation.x += 0.001*3.5
        dummy.rotation.y += 0.002*3.5
        dummy.rotation.z += 0.003*3.5
        
        if(curentObject != "diamond"){
            let pos = new THREE.Vector3();
            
            if(i == 0){
                
                dummy.getWorldDirection(pos);
                if(pos.x > 5.00001){
                    diamondUp = false;
                }
                if(pos.x < -5.008){
                    diamondUp = true;
                }
            }           
            if(diamondUp){
                dummy.position.x += (i+1)*0.0000001;
            }else{
                dummy.position.x -= (i+1)*0.0000001;
            }            
        }
        dummy.updateMatrix();

        meshDijamant.setMatrixAt(i, dummy.matrix);
    }
    meshDijamant.instanceMatrix.needsUpdate = true;

    for(let i = 0; i < objCountSijalica; i+=3){
        meshSijalica.getMatrixAt(i, matrix);
        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);

        dummy.rotation.x += 0.001*3.5
        dummy.rotation.y += 0.002*3.5
        dummy.rotation.z += 0.003*3.5
        if(curentObject != "sijalica"){
            let pos = new THREE.Vector3();
            
            if(i == 0){
                
                dummy.getWorldDirection(pos);
                if(pos.z > 5.00001){
                    sijalicaUp = false;
                }
                if(pos.z < -5.008){
                    sijalicaUp = true;
                }
            }    
            if(curentObject != "diamond" || curentObject != "logo"){
                if(sijalicaUp){
                    dummy.position.z += (i+1)*0.0000001;
                    dummy.position.x += (i+1)*0.0000004;
                }else{
                    dummy.position.z -= (i+1)*0.0000001;
                    dummy.position.x -= (i+1)*0.0000004;
                }            
            }else{
                if(sijalicaUp){
                    dummy.position.x += (i+1)*0.0000004;
                }else{
                    dummy.position.x -= (i+1)*0.0000004;
                }   
            }
        }

        dummy.updateMatrix();

        meshSijalica.setMatrixAt(i, dummy.matrix);
    }
    meshSijalica.instanceMatrix.needsUpdate = true;

    for(let i = 0; i < objCountRukovanje; i+=3){
        meshRukovanje.getMatrixAt(i, matrix);
        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);

        dummy.rotation.x += 0.001*3.5
        dummy.rotation.y += 0.002*3.5
        dummy.rotation.z += 0.003*3.5

        if(curentObject != "rukovanje"){
            let pos = new THREE.Vector3();
            
            if(i == 0){
                
                dummy.getWorldDirection(pos);
                if(pos.z > 5.00001){
                    rukovanjeUp = false;
                }
                if(pos.z < -5.008){
                    rukovanjeUp = true;
                }
            }      
            if(curentObject != "diamond" || curentObject != "logo"){     
                if(rukovanjeUp){
                    dummy.position.z += (i+1)*0.0000001;
                    dummy.position.x += (i+1)*0.0000004;
                }else{
                    dummy.position.z -= (i+1)*0.0000001;
                    dummy.position.x -= (i+1)*0.0000004;
                }            
            }else{
                if(rukovanjeUp){
                    dummy.position.x += (i+1)*0.0000004;
                }else{
                    dummy.position.x -= (i+1)*0.0000004;
                } 
            }
        }

        dummy.updateMatrix();

        meshRukovanje.setMatrixAt(i, dummy.matrix);
    }
    meshRukovanje.instanceMatrix.needsUpdate = true;

    for(let i = 0; i < objCountLogo; i+=3){
        meshLogo.getMatrixAt(i, matrix);
        matrix.decompose(dummy.position, dummy.rotation, dummy.scale);

        dummy.rotation.x += 0.001*3.5
        dummy.rotation.y += 0.002*3.5
        dummy.rotation.z += 0.003*3.5
        if(curentObject != "logo"){
            let pos = new THREE.Vector3();
            
            if(i == 0){
                
                dummy.getWorldDirection(pos);
                if(pos.z > 5.00001){
                    logoUp = false;
                }
                if(pos.z < -5.008){
                    logoUp = true;
                }
            }     
            if(curentObject != "diamond"){      
                if(logoUp){
                    dummy.position.z += (i+1)*0.0000001;
                }else{
                    dummy.position.z -= (i+1)*0.0000001;
                }            
            }else{
                if(logoUp){
                    dummy.position.x += (i+1)*0.0000004;
                }else{
                    dummy.position.x -= (i+1)*0.0000004;
                }
            }
        }
        dummy.updateMatrix();

        meshLogo.setMatrixAt(i, dummy.matrix);
    }
    meshLogo.instanceMatrix.needsUpdate = true;
    requestAnimationFrame( animate );
    render();
    controls.update();
}

animate();